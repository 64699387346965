/* global config, Promise */

'use strict';

angular.module('managerApp').controller('BoxPartnersController', (
        $scope, $location, $mdDialog, toast, boxes) => {

    $scope.config = config;
    $scope.count = 0;
    $scope.items = [];
    $scope.types = [];
    $scope.newItem = {};
    $scope.pagination = {
        currentPage: 1,
        maxSize: 2,
        itemsPerPage: 25,
        totalItems: 0,
        pageChanged: () => {
            getBoxPartners();
        }
    };
    $scope.error = null;

    const getBoxPartners = () => {
        let params = {
            partners: 1
        };
        if ($scope.pagination.filters) {
            for (let i in $scope.pagination.filters) {
                params[i] = $scope.pagination.filters[i];
            }
        }
        boxes.get(params, $scope.pagination.currentPage,
                $scope.pagination.itemsPerPage).then((data) => {
            $scope.count = data.count;
            $scope.items = data.items;
            $scope.pagination.totalItems = data.count;
            $scope.$apply();
        }).catch((err) => {
            $scope.error = err;
        });

        getTypes();

    };

    function getTypes(_scope = null) {
        boxes.get({
            types: true
        }).then((partnersTypes) => {
            if (_scope !== null) {
                _scope.items = partnersTypes;
                _scope.$apply();
            }
            $scope.types = partnersTypes;
            $scope.$apply();
        }).catch((err) => {
            toast.show(err, 'error');
        });
    }

    $scope.toggleActive = (item) => {
        item.active = !item.active;
        boxes.update(item, 'application/json', item._id).then(() => {
            toast.show('Le compte partenaire a bien été mis à jour.', 'success');
        }).catch(() => {
            toast.show('Le compte partenaire n\'a pas pu être mis à jour.', 'error');
            item.active = !item.active;
        });
    };

    $scope.showTypesPopup = () => {
        $mdDialog.show({
            templateUrl: 'app/boxes/partners/types.html',
            clickOutsideToClose: false,
            locals: {
                types: $scope.types
            },
            controller: ($scope, $mdDialog, boxes, types) => {
                $scope.items = types;
                $scope.toggleActive = (item) => {
                    item.active = !item.active;
                    boxes.update(item, 'partners-types').then(() => {
                        toast.show('Le type partenaire a bien été mis à jour.', 'success');
                    }).catch(() => {
                        toast.show('Le type partenaire n\'a pas pu être mis à jour.', 'error');
                        item.active = !item.active;
                    });
                };
                $scope.saveType = (item) => {
                    boxes.add(item, 'partners-types').then(() => {
                        $scope.newItem = {};
                        getTypes($scope);
                    }).catch((err) => {
                        toast.show(err, 'error');
                    });
                };
                $scope.deleteType = (itemId) => {
                    if (confirm('Voulez vous vraiment supprimer ce type ?')) {
                        boxes.delete(itemId, 'partners-types').then(() => {
                            getTypes($scope);
                        }).catch((err) => {
                            toast.show(err, 'error');
                        });
                    }
                };
                $scope.closeDialog = () => {
                    $mdDialog.cancel();
                };
            }
        }).then((res) => {
            if (res) {
                toast.show('Le partenaire box a bien été ajouté.', 'success');
                getBoxPartners();
            }
        }).catch((err) => {
            if (err) {
                toast.show(err && err.message ? err.message : err, 'error', true);
            }
        });
    };

    $scope.showAddEditPopup = () => {
        $mdDialog.show({
            templateUrl: 'app/boxes/partners/item.html',
            parent: angular.element(document.body),
            clickOutsideToClose: false,
            locals: {
                types: $scope.types
            },
            controller: ($scope, $mdDialog, boxes, clients, types) => {

                $scope.types = types;
                $scope.item = {};

                $scope.getPartners = (name) => {
                    return clients.get({
                        type: 'partenaire,professionnel',
                        name: name,
                        active: true
                    }).then((partners) => {
                        return Promise.resolve(partners.items);
                    }).catch((err) => {
                        return Promise.reject(err);
                    });
                };

                $scope.closeDialog = (validated = false, form = null) => {
                    if (!validated) {
                        $mdDialog.cancel();
                    } else {
                        if (!form.$valid) {
                            return;
                        }
                        if ($scope.item.partner) {
                            $scope.item.PartnerId = $scope.item.partner._id;
                        }
                        $mdDialog.hide(boxes.add($scope.item, 'partners'));
                    }//
                };
            }
        }).then((res) => {
            if (res) {
                toast.show('Le partenaire box a bien été ajouté.', 'success');
                getBoxPartners();
            }
        }).catch((err) => {
            if (err) {
                toast.show(err && err.message ? err.message : err, 'error', true);
            }
        });
    };

    $scope.showDeleteConfirm = ($event, pack) => {
        $mdDialog.show({
            parent: angular.element(document.body),
            targetEvent: $event,
            template:
                    `<md-dialog layout-padding flex="35" aria-label="List dialog">
                    <md-dialog-content layout="row" layout-wrap>
                        <div flex='100'>
                            <h3 style='margin: 0; margin-bottom: 10px;'>
                                <md-icon style='font-size: 24px; color: red'>warning</md-icon> Suppression
                            </h3>
                            <md-divider></md-divider><br>
                        </div>
                        <div>
                            <p>Voulez-vous vraiment supprimer le pack "<b>` + pack.name + `</b>" ?</p>
                            <p>Cette opération sera exécutée immédiatement et elle est <b>irréversible</b> !</p>
                        </div>
                    </md-dialog-content>
                    <md-dialog-actions>
                        <div class='buttons-container'>
                            <md-button ng-click="closeDialog()"
                                class="md-button green btn">Annuler</md-button>
                            <md-button ng-click="closeDialog(true)" 
                                class="btn btn-submit red">Confirmer la suppression</md-button>
                        </div>
                    </md-dialog-actions>
                  </md-dialog>`,
            locals: {
                packId: pack._id
            },
            controller: ($scope, $mdDialog, toast, boxes, packId) => {
                $scope.closeDialog = (confirm = false) => {
                    $mdDialog.hide();
                    if (confirm) {
                        boxes.delete(packId, 'partners').then(() => {
                            toast.show('Le compte partenaire a bien été supprimé.', 'success');
                            getBoxPartners();
                        }).catch((err) => {
                            toast.show(err);
                        });
                    }//
                };
            }
        });
    };

    getBoxPartners();

});
